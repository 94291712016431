<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CarRequestedServiceProps>(), {
  code: "defaultcode"
})
import { toRefs as __MACROS_toRefs } from "vue";
import {reset} from "@formkit/core";


import SelectedCarsBatchInvoice from "./parts/SelectedCarsBatchInvoice.vue";
import {useSelectedCarsStore} from "~/stores/selected-cars";
import {useServiceForm} from "~/composables/api";
import {useServiceModalOptions} from "~/composables/modal";

interface CarRequestedServiceProps {
  form?: object
  code?: string
  carId?: number
}

const library = markRaw({
  SelectedCarsBatchInvoice: SelectedCarsBatchInvoice
})

const {
  carId,
  form,
  code,
} = __MACROS_toRefs(__MACROS_props);

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

// const {data: form, pending} = await useServiceForm({
//   carId: +carId.value,
//   code: code.value,
//   vinFrom: vinFrom.value,
// });

const [vinCodeOptions] = useCarFieldAutocomplete({field: 'vinCode'});

const {t} = useI18n({
  useScope: 'global'
})
const onModalSubmit = async (formData, node) => {
  try {
    const client = useClient()
    const result = await client.requestedService.create.mutate(formData)
    reflectResponse(result, { emit })
  } catch (e) {
    //TODO: reflect response/error?
    node.setErrors(
      [e.message],
    )
    return false;
  }
}
const onBatchInvoiceSubmit = async (formData, node) => {
  try {
    const client = useClient()
    const carsStore = useSelectedCarsStore();
    const result = await client.car.createBatchInvoice.mutate({
      ids: formData.carId ? [formData.carId] : carsStore.cars,
      ...formData
    })
    reflectResponse(result)
    emit('close')

  } catch (e) {
    node.setErrors(
      [e.message],
    )
    return false;
  }
}

const onDamageModalReset = () => {
  reset('damage-form')
}

const onCloseModal = () => {
  reset('damage-form')
  // emit('closeModal')
}

const carsList = computed(() => {
  const selectedStore = useSelectedCarsStore()
  return selectedStore.cars
})

const initOptions = (options: object) => {
  try {
    return options
  } catch (e) {
    return []
  }
}

const initSchema = (schema: any) => {
  if (!schema) return []
  if (!Array.isArray(schema)) return initSchema([schema])
  return schema.map((item: any) => {
    if (item.children) {
      item.children = initSchema(item.children)
    }
    if (item.initOptions) {
      item.options = initOptions(item.initOptions)
      delete item.initOptions
    }
    return item
  })
}

const {applySettings} = useServiceModalOptions({})

const onFormChange = useDebounceFn(async (formData) => {
  const carsStore = useSelectedCarsStore();

  const {data} = await useServiceForm({
    carId: carId.value ? +carId.value : undefined,
    carIds: carsStore.cars,
    code: code.value,
    formData: formData
  });

  formSchema.value = initSchema(data.value.form)
  applySettings(data.value.settings)
}, 1000, { maxWait: 5000 })

const formkitData = reactive({
  t,
  vinCodeOptions,
  onCloseModal,
  onModalSubmit,
  onBatchInvoiceSubmit,
  onDamageModalReset,
  onFormChange,
  carsList,
  debug: (data: any) => JSON.stringify(data, null, 2),
})

const formSchema = ref([])

watch(form, () => {
  formSchema.value = initSchema(form.value)
  //TODO: if debug add to formkit schema
}, {immediate: true})

</script>

<template>
  <div class="requested-service-modal">
    <FormKitSchema
      :schema="formSchema"
      :data="formkitData"
      :library="library"
    />
  </div>
</template>

<style lang="scss" scoped>
.requested-service-modal {
  :deep(.two-columns) {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    .indigo-ui-outer {
      margin-right: 20px;

      &:last-child {
        margin: 0;
      }
    }
  }

  :deep(.auto-columns-grid) {
    container-type: inline-size;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(298px, 1fr));


    @container (min-width: 300px) {
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
    @container (min-width: 400px) {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
    @container (min-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
    }
    @container (min-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  :deep(.option-item-container) {
    display: grid;
    gap: 10px;
    width: 100%;
    .indigo-ui-outer {
      &[data-type="checkbox"] {
        margin-bottom: 0;
      }
    }
  }

  :deep(.section) {
    margin-bottom: 20px;
    .section-title{
      margin-bottom: 10px;
    }
  }

  :deep(.arrow) {
    width: 16px;
    height: 14px;
    margin: 25px auto 5px;
    transform: rotate(90deg);
  }
}


</style>
