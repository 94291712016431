import { toast } from 'vue-sonner'

export const download = (url: string, fileName: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("target", "_blank");
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const saveAsFile = (fileData: string, fileName: string, mime = 'text/plain', base64 = false) => {
  const element = document.createElement("a");
  if (base64) {
    element.setAttribute("href", `data:${mime};base64,${encodeURIComponent(fileData)}`);
  } else {
    element.setAttribute("href", `data:${mime};charset=utf-8,${fileData}`);
  }
  element.setAttribute("target", "_blank");
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


export const reflectResponse = (data: any, ctx: any = {}) => {
  if (!data) {
    return;
  }

  const { action } = data;

  if (action === 'download') {
    const { url, fileName } = data;
    download(url, fileName);
  }

  if (action === 'saveAsFile') {
    const { fileData, fileName, mime = 'text/plain', base64 = false } = data;
    saveAsFile(fileData, fileName, mime, base64);
  }

  if (action === 'emit') {
    //TODO: event data?
    const { emit } = ctx;
    const { event } = data;
    emit(event)
  }

  if (action === 'toast') {
    const { description, message, toastActionType } = data;
    const toastAction = ctx?.toastActions?.[toastActionType]
    toast(message, {
      description,
      action: toastAction
    })
  }


  if (action === 'multiple') {
    for (const item of data.items) {
      reflectResponse(item as any, ctx);
    }
  }
}
